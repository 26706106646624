const routers = [
    {
        path: '/',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
    },
    {
        path: '/home/:id?',
        layoutMax: true,
        component: () => import('./routes/home/home-route'),
    },
    {
        path: '/login',
        layoutMax: true,
        component: () => import('./routes/login/login-router'),
    },
    {
        path: '/page/:id', // 404
        layoutMax: true,
        component: () => import('./routes/home/page-route'),
    },
    {
        layoutMax: true,
        path: '/goods/list', //商品列表
        // models: () => [import('./models/goods-base-model')],
        component: () => import('./routes/goods/goods-list-route'),
    },
    {
        path: '/goods/detail/:id/:productId?', //商品详情
        // models: () => [import('./models/goods-base-model')],
        component: () => import('./routes/goods/goods-detail-route'),
    },
    {
        path: '/cart', //购物车
        models: () => [import('./models/cart/cart-model')],
        component: () => import('./routes/cart/cart-route'),
    },
    {
        path: '/order/create', // 订单确认
        component: () => import('./routes/order/order-create-route'),
    },
    {
        path: '/order/create/result', // 下单成功页（后付）
        component: () => import('./routes/order/order-create-result-route'),
    },
    {
        path: '/order/pay/result/:id/:payType/:orderType?', //支付结果页
        component: () => import('./routes/order/order-pay-result-route'),
    },
    {
        path: '/order/pay/result', //支付结果页
        component: () => import('./routes/order/order-pay-result-route'),
    },
    {
        path: '/order/pay/:id?/:orderType?',//支付页
        component: () => import('./routes/order/order-pay-route'),
    },
    {
        path: '/order/list', // 订单列表
        showMenu: true,
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-list-route.js'),
    },
    {
        path: '/order/detail/:id', // 订单详情
        models: () => [import('./models/order/order-model')],
        component: () => import('./routes/order/order-detail-route.js'),
    },
    {
        path: '/order/log', // 订单日志
        component: () => import('./routes/order/order-log-route.js'),
    },
    {
        path: '/workorder/list', // 服务单列表
        showMenu: true,
        component: () => import('./routes/workorder/workorder-list-route.js'),
    },
    {
        path: '/workorder/detail/:id', // 服务单详情
        component: () => import('./routes/workorder/workorder-detail-route.js'),
    },
    {
        path: '/workorder/log', // 服务单日志
        component: () => import('./routes/workorder/workorder-log-route.js'),
    },
    {
        path: '/user', // 个人中心
        showMenu: true,
        component: () => import('./routes/user/user-route.js'),
    },
    {
        path: '/deposit/detail', // 对账明细
        showMenu: true,
        component: () => import('./routes/user/deposit-details-route'),
    },
    {
        path: '/account', // 账户信息
        showMenu: true,
        component: () => import('./routes/user/account-route'),
    },
    {
        path: '/address', // 收货地址
        showMenu: true,
        component: () => import('./routes/user/address-route'),
    },
    {
        path: '/notices/list', // 通知公告列表
        showMenu: true,
        component: () => import('./routes/user/notices-route'),
    },
    {
        path: '/notices/detail/:id', // 通知公告详情
        component: () => import('./routes/user/notices-detail-route'),
    },
    {
        path: '/order/message', // 订单消息
        showMenu: true,
        component: () => import('./routes/user/order-message-route'),
    },
    {
        path: '/refund/list', // 退货单
        showMenu: true,
        component: () => import('./routes/refund/refund-list-route'),
    },
    {
        path: '/refund/detail/:id', // 退货单详情
        component: () => import('./routes/refund/refund-detail-route'),
    },
    {
        path: '/help/detail', // 帮助详情
        component: () => import('./routes/user/help-detail-route'),
    },
    {
        path: '/collect/list', // 收藏列表
        showMenu: true,
        component: () => import('./routes/user/collect-list-route'),
    },
    {
        path: '/keep/list', // 常用清单
        showMenu: true,
        component: () => import('./routes/user/keep-list-route'),
    },
    {
        path: '/wechat/pay', // 微信支付
        layoutMax: true,
        component: () => import('./routes/other/wechat-pay'),
    },
    {
        path: '/yisheng/scan/pay', // 易生扫码支付
        layoutMax: true,
        component: () => import('./routes/other/yisheng-scan-pay'),
    },
    {
        path: '/mercado/pay', // mercado支付
        layoutMax: true,
        component: () => import('./routes/other/mercado-pay'),
    },
    {
        path: '/experience/list', // 线下体验馆
        layoutMax: true,
        component: () => import('./routes/experience/experience-list-route'),
    },
    {
        path: '/experience/detail/:id', // 线下体验馆
        component: () => import('./routes/experience/experience-detail-route'),
    },
    {
        path: '/goods/brand/list', // 品牌汇
        component: () => import('./routes/goods/goods-brand-list'),
    },
    {
        path: '/goods/sn/search', // 商品串号查询
        component: () => import('./routes/goods/goods-sn-search-route'),
    },
    {
        path: '/invoice/list', // 发票明细
        showMenu: true,
        component: () => import('./routes/user/invoice/invoice-list-route'),
    },
    {
        path: '/invoice/detail/:id', // 发票详情
        showMenu: true,
        component: () => import('./routes/user/invoice/invoice-detail-route'),
    },
    {
        path: '/invoice/pre-depoist', // 预存款管理
        showMenu: true,
        component: () => import('./routes/user/pre-deposit/pre-deposit-route'),
    },
    {
        path: '/invoice/shop-record-list', // 资金流水
        showMenu: true,
        component: () => import('./routes/user/shop-record-list/index-route')
    },
    {
        title:'优惠券',
        path: '/coupon/list', // 优惠券
        showMenu: true,
        component: () => import('./routes/user/coupon-list-route'),
    },
    {
        title:'领券中心',
        path: '/coupon/center', // 领券中心
        showMenu: false,
        layoutMax: true,
        component: () => import('./routes/user/coupon-center-route'),
    },
    {
        title:'套餐列表',
        path: '/goods/package/list', // 套餐列表
        component: () => import('./routes/goods/goods-package-list.js'),
    },
    {
        path: '/goods/package/detail/:id', // 商品套餐详情
        component: () => import('./routes/goods/goods-package-detail'),
    },
    {
        path: '*', // 404
        component: () => import('./routes/other/404-route'),
    },


];

export default routers;

