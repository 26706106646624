import React, {Fragment} from 'react';
import {render} from 'react-dom'
import QRCode from 'qrcode.react';
// import UrlUtil from "./url-config";
import StorageUtil from "./storage-util";
import {LocalConst} from "./constant";
import IMGAlipay from './../assets/images/pay-alipay.png'
import IMGWeChatPay from './../assets/images/pay-wechat.png'
import CommonUtil from "@utils/common-util";
import {Modal} from 'antd';

const RenderUtil = {
    renderLoginModal() {
        const titleTips = '登录过期';
        // @ts-ignore
        const contentTips = '登录信息已经过期，请重新登录';
        Modal.error({
            centered: true,
            title: titleTips,
            content: contentTips,
            okText: '登录',
            onOk: () => {
                CommonUtil.logout();
            }
        })
    },
    renderPayModal(conf) {
        const node = document.createElement('section');
        document.body.appendChild(node);
        this.node.id = 'jsPayModalBox';
        const {type, qrcodeUrl, needPay, refreshFunc} = conf;
        const companyName = StorageUtil.getItem(LocalConst.companyName);
        const payType = {wx_pub_qr: '微信', alipay_qr: '支付宝'};
        const payTypeLogo = {wx_pub_qr: IMGWeChatPay, alipay_qr: IMGAlipay};
        // 关闭弹窗
        const closeModal = () => {
            if (refreshFunc && typeof refreshFunc === 'function') {
                refreshFunc()
            }
            document.body.removeChild(node);
        };
        render(
            <Fragment>
                <div className="pay-modal">
                    <div className="pay-modal-title">
                        {`${payType[type]}支付`}

                        <span className="close" role="none" onClick={closeModal}><i
                            className="iconfont icon-cancel"/></span>
                    </div>
                    <div className="pay-modal-body">
                        <div className="pay-modal-body-head">
                            <div className="left-box">
                                <p>{`正在使用${payType[type]}扫码交易`}</p>
                                <p>{`收款方：${companyName}`}</p>
                            </div>
                            <div className="logo-box">
                                <img src={payTypeLogo[type]} alt=""/>
                            </div>
                        </div>
                        <div className="pau-modal-body-main">
                            <div className="main-box">
                                <div className="title-box">扫一扫付款（元）</div>
                                <div className="price-box">{needPay}</div>
                                <div className="qrcode-box">
                                    <QRCode size={136} value={qrcodeUrl}/>
                                </div>
                                <div className="tips-box">
                                    请使用{payType[type]}扫描<br/>
                                    二维码完成支付
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="mask"/>
            </Fragment>, node)
    },
};

export default RenderUtil;

