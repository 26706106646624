import {message} from 'antd'

const suffixHost = 'mall.aqara.com'
const apiEnv = process.env.API_ENV;
console.log('apiEnv', apiEnv)
const UrlUtil = {
    getHost(preHost){
        if (process.env.API_ENV === 'prod') {
            if (preHost === 'false'){
              return `${suffixHost}`;
            }else{
              return `${preHost}-${suffixHost}`;
            }
          } else {
            if (preHost === 'false'){
              return `${apiEnv}-${suffixHost}`;
            }else{
              return `${apiEnv}-${preHost}-${suffixHost}`;
            }
        }   
    },
    getAPI() {
        const host = window.location.host;
        let evn = 'dev';
        if (host.includes('sit')) {
            evn = 'sit';
        }
        if (host.includes('uat')){
            evn = 'uat';
        }
        if (host.includes('pre')){
            evn = 'pre';
        }
        // TODO: 生产地址有问题
        if (process.env.API_ENV === 'prod') {
        // TODO: 生产地址有问题
            return `https://b-gw.aqara.com/mall`;
        } 
        return `https://${evn}-gw-mall.aqara.com/mall`;
    },
    getAccountUrl: (companyID) => {
        if (process.env.API_ENV === 'prod') {
            return companyID ? `https://store${companyID}-mall.aqara.com` : `https://account-mall.aqara.com`
        } else {
            return companyID ? `https://store${companyID}-${apiEnv}-mall.aqara.com` : `https://${apiEnv}-account-mall.aqara.com`
        }
    },
    getPCShopUrl: (companyID) => {
        if (!companyID) return message.info('缺少企业id');
        if (process.env.API_ENV === 'prod') {
            return `https://store${companyID}-mall.aqara.com`
        } else {
            return `https://store${companyID}-${apiEnv}-mall.aqara.com`
        } 
    },
    getPCMgrUrl: (companyID) => {
        if (!companyID) return message.info('缺少企业id');
        if (process.env.API_ENV === 'prod') {
            return `https://store${companyID}-mall.aqara.com`
        } else {
            return `https://store${companyID}-${apiEnv}-mall.aqara.com`
        }
    },
    getMShopUrl: () => {
        if (process.env.API_ENV === 'prod') {
            return `https://m-mall.aqara.com`
        }else {
            return `https://${apiEnv}-m-mall.aqara.com`
        }
    },

    getMMgrUrl() {
        if (process.env.API_ENV === 'prod') {
            // return `http://mtest.qipeidd.cn`;
            return `https://mgr-mall.aqara.com`;
        } else  {
            return `https://${apiEnv}-mgr-mall.aqara.com`;
        } 
    },
    getLightUrl: () => {
        if (process.env.API_ENV === 'prod') {
            return `https://pd.mall.aqara.com`
        } else {
            return `https://pd.${apiEnv}-mall.aqara.com`
        }
    },
    getBitMapUrl: () => {
        if (process.env.API_ENV === 'prod') {
            return `https://point.mall.aqara.com`
        } else {
            return `https://point.${apiEnv}-mall.aqara.com`
        }
    },
};
export default UrlUtil;
