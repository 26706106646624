import React from 'react';
import {connect} from "dva";
import {IntlProvider} from "react-intl";

import zh_CN from "../../locale/zh_CN"; // 中文
import es_Es from "../../locale/es_ES" // 西班牙语
import en_US from "../../locale/en_US" // 西班牙语
// 多语言
const messages = {
    'zh': zh_CN,
    'es': es_Es,
    'en': en_US
};

@connect(state => ({...state.appModel}))
class IntlLayout extends React.Component {
    render() {
        const {lang = 'zh'} = this.props || {};
        return (
            <IntlProvider locale={lang} messages={messages[lang]}>
                {this.props.children}
            </IntlProvider>
        )
    }
};
export default IntlLayout
