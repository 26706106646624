import { LocalConst } from "./constant";
import StorageUtil from "./storage-util";

let styleEl = null;
const genertateWatermark = function (content, classNames) {
  /**
   * @name 创建基础图片
   * @param {*} options 
   */
  const createBaseImg = (options) => {
    const { content, width = 140, height = 150, fontSize = 14, color = 'rgba(100,100,100,0.2)' } = options;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      // ctx.rotate((-10 * Math.PI) / 180); // 偏移一点距离
      ctx.rotate((-24 * Math.PI) / 180); // 偏移一点距离
      ctx.fillStyle = color; // 设置绘制的颜色
      ctx.font = `${fontSize}px Arial`; // 设置字体的大小
      let startY = height - fontSize * 1.5 * content.length;
        // 遍历水印内容
        content.forEach((text, index) => {
        //   ctx.fillText(text, 10, 30 * (index + 1)); // 拉开30的间距
            ctx.fillText(text, 2, startY + fontSize * (index + 1)); // 拉开30的间距
        });
    }
    return canvas.toDataURL('image/png'); // 转换程data url，可供img直接使用
  }
  // const listenerDOMChange = (className) => {
  //   const targetNode = document.querySelector(`.${className}`);
  //   const observer = new MutationObserver((mutationsList) => {
  //     for (let mutation of mutationsList) {
  //       if (mutation.type === 'attributes' && mutation.attributeName === 'class' && targetNode) { // 监听属性并且属性名为class的变更
  //         const curClassVal = targetNode.getAttribute('class') || '';
  //         if (curClassVal.indexOf(className) === -1) { // 监听到className被删除了，手动加回去
  //           targetNode.setAttribute('class', `${className} ${curClassVal}`);
  //         }
  //       }
  //     }
  //   });

  //   observer.observe(targetNode, {
  //     attributes: true,
  //   });
  // };

  const dataURL = createBaseImg({
    content,
    width: 340,
    height: 240,
    fontSize: 20,
    color: 'rgba(153,153,153, .8)'
  })
  const defaultStyle = document.createElement('style');
  const nameStrS = classNames.map((className) => {
    let nameStr = `.${className}::after`;
    return nameStr
  })

  defaultStyle.innerHTML = `
      /* 定义一个名为fadeIn的关键帧动画 */
      @keyframes fadeIn {
          from { opacity: 0;}
          to { opacity: 0.3; }
      }
      ${nameStrS.join(',')}{
        content: '';
        display: block;
        width: 100%;
        height: 100vh;
        background-image: url(${dataURL});
        background-repeat: repeat;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        animation: fadeIn 0.3s linear forwards;
        }; 
        `;
  document.head.appendChild(defaultStyle);
  if (styleEl) {
    styleEl.remove()
  }
  styleEl = defaultStyle;
  // document.head.appendChild(stylePop);
  // classNames.forEach((className) => {
  //     // 监听class的变更
  //     listenerDOMChange(className);
  // })
}
const padLeftZero = (str) => `00${str}`.substr(str.length)
const formatDate = function (date, fmt) {
  // 将时间'2018-05-08 12:00:00'转为'2018/05/08 12:00:00', 为兼容ie浏览器
  date = typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = `${o[k]}`;
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
window.addEventListener('load', function () {
  
  const timeStr = formatDate(new Date(), 'yyyy-MM-dd');
  let info =StorageUtil.getItem(LocalConst.mShop_user) || {};
  
  if (info) {
    let name = info.name_ || info.client_name_ || info.email_ || 'Aqara';
    // let name = '莫力达瓦达斡尔族自治旗尼尔基镇新米家智能家居科技馆';
    // let name = 'information-center@aqara.com';
    const contents = [name];
    let reg = /\w[-.\w]*@[-a-z0-9]+(\.[-a-z0-9]+)*\.(com|cn|edu|uk)/ig;
    let len = 13;
    if (reg.test(name)) {
        len = 28;
    }
    if (name.length > len) {
        contents[0] = name.slice(0, len)
        contents[1] = name.slice(len, name.length)
    }
    contents.push(timeStr);
    const classNames = [
      'page-box-wrap',
      'loginWrap .login-bg-wrapper',
      'ant-modal-wrap',
      'ant-drawer-content',
      // 'menu-box',
      'menu-sub-box',
      // 'cont-list-table-head .head',
      // 'cont-list-table-head',
      // 'bg-main',
    ]
    genertateWatermark(contents, classNames)
  }
})

// 监听localStorage的变化事件
window.addEventListener('setItemEvent', function (e) {
  // 检查变化的键是我们关心的键
  if (e.key === 'mShop_user' && e.newValue) {
    // let info =StorageUtil.getItem(LocalConst.mShop_user) || {};
    let info =JSON.parse(e.newValue) || {};
  
    const timeStr = formatDate(new Date(), 'yyyy-MM-dd');
    let name = info.name_ || info.client_name_ || info.email_ || 'Aqara';
    const contents = [name];
    let reg = /\w[-.\w]*@[-a-z0-9]+(\.[-a-z0-9]+)*\.(com|cn|edu|uk)/ig;
    let len = 13;
    if (reg.test(name)) {
        len = 28;
    }
    if (name.length > len) {
        contents[0] = name.slice(0, len)
        contents[1] = name.slice(len, name.length)
    }
    contents.push(timeStr);
    const classNames = [
      'page-box-wrap',
      'loginWrap .login-bg-wrapper',
      'ant-modal-wrap',
      'ant-drawer-content',
      // 'menu-box',
      'menu-sub-box',
    ]
    genertateWatermark(contents, classNames)
  }
});

