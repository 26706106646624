import React from 'react';
import './footerCompanyInfo.scss';

const FooterCompanyInfo = () => {

    // const renderShopItem = () => {
    //     const shopList = [
    //         {
    //             name: '微信',
    //             className: 'weixin',
    //             qcord: '',
    //         },
    //         {
    //             name: '视频号',
    //             className: 'shipinhao',
    //             qcord: '',
    //         },
    //         {
    //             name: '微博',
    //             className: 'weibo',
    //             qcord: '',
    //         },
    //         {
    //             name: '抖音',
    //             className: 'douyin',
    //             qcord: '',
    //         },
    //         {
    //             name: '哔哩哔哩',
    //             className: 'bili',
    //             qcord: '',
    //         }
    //     ]

    //     return shopList.map((item, index) => {
    //         const className = ['shopItem', item.className].join(' ')
    //         return (<div className={className} key={item.className}></div>)
    //     })
    // }
    return (<div className="FooterCompanyInfo">
        {/* <div className="footerTop">
            <div className="left-box">
                <a href="##" class="footetLogo">
                    <img src="https://static-resource.aqara.com/img/footer_logo.png" alt="footer_logo" className="f_l_w" />
                </a>
                <a href="tel:400-990-7930" class="footerTel">客服热线：400-990-7930</a>
            </div>
            <div className="right-box">
                {
                    renderShopItem()
                }
            </div>
        </div> */}
        <div className='copyrightWrap'>
            <div>
                <a href='https://beian.miit.gov.cn/#/Integrated/index' rel="noopener noreferrer" target='_blank'>
                    Copyright ® 2022 深圳绿米联创科技有限公司版权所有 粤ICP备15041247号
                </a>
                <a href='https://beian.mps.gov.cn/#/query/webSearch?code=44030502001722' target='_blank' rel="noopener noreferrer" >
                    <img src="https://static-resource.aqara.com/img/recordimg.png" alt='recordimg'></img>
                    深公网安备 44030502001722号
                </a>
            </div>
            <div className='address'>联系地址：深圳市南山区桃源街道留仙大道南山智园崇文园区1号楼8楼</div>
            <div className='tel'>联系电话：400-990-7930</div>
        </div>
    </div>)
};


export default FooterCompanyInfo;