import React, {Fragment} from 'react';
import classnames from 'classnames'
import './style.scss'
import {injectIntl} from 'react-intl'

@injectIntl
class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    toPage = (item) => {
        const {history} = this.props;
        history.push(item.router)
    };

    render() {
        const {pathname, intl} = this.props;
        this.intl = intl;
        const menuList = [
            {
                name: (this.intl.messages['WoDe'] || '我的'),
                children: [
                    {
                        name: (this.intl.messages['GeRenZhongXin'] || '个人中心'),
                        router: '/user'
                    },
                    {
                        name: (this.intl.messages['TongZhiGongGao'] || '通知公告'),
                        router: '/notices/list'
                    },
                    {
                        name: (this.intl.messages['DingDanXinXi'] || '订单信息'),
                        router: '/order/message'
                    },
                    {
                        name: (this.intl.messages['ShouCangShangPin'] || '收藏商品'),
                        router: '/collect/list'
                    },
                    {
                        name: (this.intl.messages['GouMaiJiLu'] || '购买记录'),
                        router: '/keep/list'
                    },
                    {
                        name: (this.intl.messages['YouHuiQuan'] || '优惠券'),
                        router: '/coupon/list'
                    }
                ]
            },
            {
                name: (this.intl.messages['DingDanGuanLi'] || '订单管理'),
                children: [
                    {
                        name: (this.intl.messages['DingHuoDan'] || '订货单'),
                        router: '/order/list'
                    },
                    {
                        name: (this.intl.messages['FuWuDan'] || '服务单'),
                        router: '/workorder/list'
                    },
                    {
                        name: (this.intl.messages['TuiKuanDan'] || '退款单'),
                        router: '/refund/list'
                    },
                ]
            },
            {
                name: (this.intl.messages['CaiWuGuanLi'] || '财务管理'),
                children: [
                    
                    {
                        name: (this.intl.messages['YuCunKuanGuanLi'] || '预存款管理'),
                        router: '/invoice/pre-depoist'
                    },
                    {
                        name: (this.intl.messages['ZiJinGuanLI'] || '资金管理'),
                        router: '/invoice/shop-record-list'
                    },
                    // {
                    //     name: (this.intl.messages['DuiZhangMingXi'] || '对账明细'),
                    //     router: '/deposit/detail'
                    // },
                    {
                        name: (this.intl.messages['FaPiaoGuanLi'] || '发票管理'),
                        router: '/invoice/list',
                        detail: '/invoice/detail',
                    },
                ]
            },
            {
                name: (this.intl.messages['XiTongSheZhi'] || '系统设置'),
                children: [{
                    name: (this.intl.messages['ZhangHaoXinXi'] || '帐号信息'),
                    router: '/account'
                }, {
                    name: (this.intl.messages['ShouHuoDiZhi'] || '收货地址'),
                    router: '/address'
                },]
            },
        ];

        return (
            <section className="user-menu-box">
                <dl>
                    {
                        menuList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <dt>{item.name}</dt>
                                    {
                                        item.children.map((subItem, sIndex) => {
                                            const cls = classnames({'active': subItem.router === pathname || pathname.includes(subItem.detail)});
                                            return (
                                                <dd className={cls} role="none" key={sIndex}
                                                    onClick={() => this.toPage(subItem)}>{subItem.name}</dd>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </dl>
            </section>
        )
    }
}

export default UserMenu
