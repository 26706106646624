import request from '../utils/request';
import UrlUtil from '../utils/url-config';
import axios  from 'axios';

const API = UrlUtil.getAPI();
//商品模块接口
export const ShopFetch = {
    //分页获取首页公告列表
    searchNoticeList: (params, success) => {
        return request('BODY', `${API}/shopping/notice/getPage`, params, success)
    },
    //获取公告详情
    getNoticeById: (params, success) => {
        return request('GET', `${API}/shopping/notice/getDetail`, params, success)
    },
    // 获取未读消息数量
    getUnreadNoticeNum: (params, success) => {
        return request('GET', `${API}/shopping/notice/getNotReadCount`, params, success)
    },
    //获取商品详情
    getGoodsById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsById/` + params, params, success)
    },
    //商城商品分页查询
    searchListForShop: (params, success) => {
        return request('BODY', `${API}/v1/shop/goods/noAuth/searchListForShop`, params, success)
    },
    //web端查询收藏商品
    searchFavoriteGoodsForWeb: (params, success) => {
        return request('body', `${API}/v1/shop/goods/searchFavoriteGoodsForWeb`, params, success)
    },
    //web端收藏商品分类筛选
    searchTypeByFavorite: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByFavorite`, params, success)
    },
    //web端查询常购商品清单
    searchClientGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchClientGoodsForWeb`, params, success)
    },
    //web端常购商品分类筛选
    searchTypeByClientGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByClientGoods`, params, success)
    },
    //获取指定公司的商品分类树
    getUsePropertyTree: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getUsePropertyTree`, params, success)
    },
     //获取指定公司的商品分类树 新增二级
     getUsePropertyTree2: (params, success) => {
        return request('GET', `${API}/shopping/common/getPropertyTree`, params, success)
    },
    addGoodsFavorite: (_params, _success, _error) => {
        request('BODY', `${API}/v1/shop/goodsFavorite/addGoodsFavorite`, _params, _success, _error)
    },
    deleteGoodsFavorite: (_params, _success, _error) => {
        request('BODY', `${API}/v1/shop/goodsFavorite/deleteGoodsFavorite`, _params, _success, _error)
    },
    //移出收藏夹
    batchDeleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchDeleteGoodsFavorite`, params, success)
    },
    //列出企业所有商品品牌
    listBrandByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    //列出企业所有商品分类
    listTypeByCompany: (params, success) => {
        return request('GET', `${API}/shopping/common/goods/getTypeTree`, params, success)
    },
    //列出企业所有商品标签
    searchTagList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listTagByCompany`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/shopping/common/setting/getSaleSetting/${params}`, params, success)
    },
    //获取商城开放注册应用设置
    getShopRegisterSetting: (params, success) => {
        return request('GET', `${API}/v1/shopRegisterSetting/noAuth/getByCompany`, params, success)
    },
    //获取企业信息
    getCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getCompany/${params}`, params, success)
    },
    // 获取装修信息
    getPageDataByPc: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/getPageDataByPc`, params, success)
    },
    // 获取装修信息
    getPcTemplateById: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/getPcTemplateById/${params}`, params, success)
    },
    // 获取店铺装修商品
    searchListForDecorate: (params, success) => {
        return request('body', `${API}/decorate/admin/setting/getPutAwayPage`, params, success)
        // return request('GET', `${API}/v1/shop/decorate/noAuth/searchListForDecorate`, params, success)
    },
    // 获取店铺装修商品
    searchListForHome: (params, success) => {
        return request('body', `${API}/shopping/home/page/getShoppingHomePage`, params, success)
    },
    // 获取店铺装修商品
    countDecorateVisitor: (params, success) => {
        return request('POST', `${API}/v1/decorate/wpShopTemplate/noAuth/countVisitor/${params}`, params, success)
    },
    // 获取店铺装修促销
    listForGoodsPromotionActivityIds: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/listForGoodsPromotionActivityIds`, params, success)
    },
    // //获取运费计算设置
    // getShipFeeSettingByLogin: (params, success) => {
    //     return request('GET', `${API}/v1/setting/shipFeeSetting/getByLoginUser/${params}`, params, success)
    // },
    //获取运费计算设置
    getShipFeeSettingByLogin: (params, success) => {
        return request('GET', `${API}/v1/setting/shipFeeSetting/getAllShipFeeSetting/${params}`, params, success)
    },
    //获取预存款明细
    searchFinanceDetail: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchPayLog`, params, success)
    },
    //记录浏览商品足迹
    saveGoodsViewLog: (params, success) => {
        return request('BODY', `${API}/v1/goods/goodsViewLog/save`, params, success)
    },
    //获取推荐商品
    searchRecommendedGoodsListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchRecommendedGoodsListForShop`, params, success)
    },
    // 根据分类查询关联品牌
    searchBrandList: (params, success) => {
        return request('GET', `${API}/shopping/common/goods/getBrandList`, params, success)
    },
    // 查询套餐列表数据
    searchGoodsPackageList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsPackageListForShop`, params, success)
    },
    //获取商品优惠信息
    getGoodsPackageInfoById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsPackageByIdForWeb/${params}`, params, success)
    },
};
//购物车模块接口
export const CartFetch = {
    // 保存/修改商城购物车列表
    batchModify: (params, success) => {
        return request('BODY', `${API}/v1/shop/cart/updateForWeb`, {list: params}, success)
    },
    //获取购物车列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchList`, params, success)
    },
    //webapp再来一单添加商品合并到购物车
    mergeCartForWeb: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/cart/mergeCartForWeb`, _params, _success, _error)
    },
    //购物车查询
    searchListForShopCart: (params, success) => {
        return request('GET', `${API}/shopping/cart/searchListForShopCart`, params, success)
    },
    //删除商城购物车
    batchDelete: (params, success) => {
        return request('BODY', `${API}/shopping/cart/remove`, params, success)
    },
    //获取商品优惠信息
    getPromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/shopping/goodsPromotion/getPromotionByGoodsId`, params, success)
    },
    //根据规格ids串获取商品促销信息
    getPromotionByProductIds: (params, success) => {
        return request('GET', `${API}/shopping/goodsPromotion/getPromotionByProductIds`, params, success)
    },
    //获取商品优惠信息
    getActivityById: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getActivityById/${params}`, null, success)
    },
    //获取订单优惠信息
    getPromotionByCompanyId: (params, success) => {
        return request('GET', `${API}/shopping/orderPromotion/getPromotionByCompanyId`, params, success)
    },
    //获取购物车SKU总数量
    countNumber: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/countNumber`, params, success)
    },
    //批量添加商城商品收藏
    batchAddGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchAddGoodsFavorite`, params, success)
    },
    //获取商品预售信息
    getpresalePromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/shopping/presalePromotion/getPromotionByGoodsId`, params, success)
    },
    //获取商品预售信息
    getPresalePromotionByProductIds: (params, success, error) => {
        return request('BODY', `${API}/shopping/presalePromotion/getPromotionByProductId`, params, success, error)
    },
    // 获取配送方式（物流快递）
    getShipWayList: (params, success) => {
        return request('GET', `${API}/shopping/companyLogistic/getList`, params, success)
    },
    // 获取第三方运费
    getThirdFreight: (params, success) => {
        return request('BODY', `${API}/shopping/order/calShipFee`, params, success)
    },
    // 获取门店地址
    getShopAddressList: (params, success) => {
        return request('GET', `${API}/shopping/store/getList`, params, success)
    },
    //保存订单
    save: (params, success) => {
        return request('BODY', `${API}/shopping/order/createOrder`, params, success)
    },
    //保存预售订单
    presaleSave: (params, success) => {
        return request('BODY', `${API}/shopping/order/presaleSave`, params, success)
    },
    // 新版添加商城商品收藏
    addGoodsFavorite: (params, success) => {
        return request('BODY', `${API}/v1/shop/goodsFavorite/addGoodsFavorite`, params, success)
    },
    // 新版删除商城商品收藏
    deleteGoodsFavorite: (params, success) => {
        return request('BODY', `${API}/v1/shop/goodsFavorite/deleteGoodsFavorite`, params, success)
    },
    // 获取定制信息
    getProductCustomInfo: (params, success) => {
        return request('GET', `${API}/shopping/cart/getCustomizedList`, params, success)
    },
    // 保存定制信息
    saveProductCustomInfo: (params, success) => {
        return request('BODY', `${API}/shopping/cart/saveOrderItemCustomized`, params, success)
    },
    // 获取手风琴轨道手工费
    getAccordionTrackFee: (params, success) => {
        return request('GET', `${API}/shopping/cart/getAccordionAdditionFee`, params, success)
    }
};
//商城订单
export const OrderFetch = {
    // 获取订单详情
    getById: (params, success) => {
        return request('GET', `${API}/shopping/order/getById/` + params, params, success)
    },
    //订单支付(线下或预付款)
    orderPay: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success)
    },
    //获取订单列表
    searchListForShop: (params, success) => {
        return request('BODY', `${API}/shopping/order/getPage`, params, success)
    },
    //取消订单
    orderCancel: (params, success) => {
        return request('BODY', `${API}/shopping/order/cancel`, params, success)
    },
    //删除订单
    markNotViewForShop: (params, success) => {
        return request('DELETE', `${API}/v1/shop/order/markNotViewForShop/${params}`, params, success)
    },
    //确认收货
    receiveBySaleOrder: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/receive`, params, success)
    },
    //确认收货
    receiveByOrderId: (params, success) => {
        request('GET', `${API}/shopping/order/receiveByOrder`, params, success)
    },
    //确认收货
    receive: (params, success) => {
        return request('BODY', `${API}/shopping/order/receive`, params, success)
    },
    // 根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/v1/common/helper/getLogisticInfo`, params, success)
    },
    // 分页获取商城订单消息列表
    searchOrderMessage: (params, success) => {
        return request('GET', `${API}/v1/shop/orderMessage/searchListByLogin`, params, success)
    },
    // 标记订单消息为已读
    markRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markRead/${params}`, null, success);
    },
    // 添加日志
    saveOrderLog: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/order/saveOrderLog`, _params, _success, _error)
    },
    // 获取银联、支付宝支付配置
    getShopPaySetting:(params, success) => {
        return request('GET', `${API}/shopping/common/setting/getOnlinePayList`, params, success)
    },
    // 返利和余额支付
    usedBalancePayToOrder:(params, success) => {
        return request('BODY', `${API}/shopping/order/combinationPay`, params, success)
    },
    // 线上支付：支付宝/银联支付
    usedOnlinepayToOrder:(params, success) => {
        return request('BODY', `${API}/shopping/order/buildOrderOnlinePay`, params, success)
    },
    // 详情获取定制信息
    getProductCustomInfo: (params, success) => {
        return request('GET', `${API}/shopping/cart/getCustomizedListByOrderItem`, params, success)
    },
    // 获取订单可发工单商品列表
    getWorkorderProductList: (params, success) => {
        return request('GET', `${API}/shopping/order/getOrderServiceProductList`, params, success)
    },
    // 创建工单
    createWorkorder: (params, success) => {
        return request('BODY', `${API}/shopping/serviceOrder/createServiceOrder`, params, success)
    },
    // 获取工单列表
    getWorkorderList: (params, success) => {
        return request('BODY', `${API}/shopping/serviceOrder/getServiceOrderPage`, params, success)
    },
    // 获取工单详情
    getWorkorderById: (params, success) => {
        return request('GET', `${API}/shopping/serviceOrder/getById/${params}`, params, success)
    },
    // 取消服务单
    workorderCancel: (params, success) => {
        return request('BODY', `${API}/shopping/serviceOrder/cancel`, params, success)
    },
};
//系统模块
export const SystemFetch = {
    // 取当前登录人关联的所有账号信息列表
    getByHost(params) {
        return request(`GET`, `${API}/v1/base/company/noAuth/getByHost`, params);
    },
    //订货端获取企业设置
    getShopCompanySetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopCompanySetting/${params}`, params, success)
    },
    //管理端-体验
    experience: (params, success) => {
        return request('POST', `${API}/v1/base/experience/toPcMgr`, params, success)
    },
    //订货端-体验
    shopExperience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, {...params, type_: 2}, success)
    },
    //获取登陆用户信息
    getClientById: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientById/${params}`, params, success)
    },
    //获取地址
    listAll: (params, success) => {
        return request('GET', `${API}/v1/shop/address/listByLoginUser`, params, success)
    },
    //获取地址
    deleteAddress: (params, success) => {
        return request('DELETE', `${API}/v1/shop/address/deleteById/${params}`, params, success)
    },
    //保存、修改地址
    saveAddress: (params, success) => {
        return request('BODY', `${API}/shopping/clientAddress/save`, params, success)
    },
    //设置默认地址
    getUserInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfo`, params, success)
    },
    // 保存用户客户信息
    saveUserClientInfo: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveUserClientInfo`, params, success)
    },
    // 获取文件上传的token信息
    getUploadToken: (params, success) => {
        return request('GET', `${API}/v1/common/upload/getUploadToken`, params, success)
    },
    //获取当前登录用户的authCode
    getAuthCode: (params, success) => {
        return request('GET', `${API}/v1/account/getAuthCode`, params, success)
    },
    //获取省市县
    buildSimpleRegionTree: (params, success) => {
        return request('GET', `${API}/common/select/getAddressList`, params, success)
    },
    // 获取街道
    listStreetById: (params, success) => {
        return request('GET', `${API}/v1/common/helper/listStreetById/${params}`, params, success)
    },
    //获取微信jsticket
    getWxTicket: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxTicket`, params, success)
    },
    // 搜索框  获取焦点 未输入搜索关键词前 =》 查出客户搜索历史关键词5个
    searchClientKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchClientKeyWordList`, params, success)
    },
    // 关键词搜索
    searchKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchKeyWordList`, params, success)
    },
    // 删除客户指定的搜索关键词
    toDeleteKeywordsById: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/deleteById/${params}`, params, success)
    },
    // 删除客户所有的搜索关键词
    toDeleteClientAllKeywords: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/toDeleteClientAllKeywords`, params, success)
    },
    // 个人中心
    getShopUserInfoForHome: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfoForHome`, params, success)
    },
    // 获取商城帮助
    shopHelpTypeSearchList: (params, success) => {
        return request('GET', `${API}/v1/shopHelp/shopHelpType/noAuth/searchListForShop`, params, success)
    },
    // 获取微信用户信息
    getWeChatUserInfo: (params, success) => {
        return request('GET', `${API}/v1/setting/weChatSetting/noAuth/getWeChatUserInfo`, params, success)
    },
    // 微信openId登录
    openIdLogin: (params, success) => {
        return request('POST', `${API}/v1/account/openIdLogin`, params, success)
    },
    // 绑定微信openId
    bindOpenId: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenId`, params, success)
    },
    //获取应用状态
    getApplicationStatus: (params, success) => {
        return request('GET', `${API}/v1/application/noAuth/getApplicationStatus`, params, success)
    },
    //
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    searchExperienceShopList: (params, success) => {
        return request('GET', `${API}/v1/store/experienceShop/searchList`, params, success)
    },
    getExperienceShopById: (params, success) => {
        return request('GET', `${API}/v1/store/experienceShop/getById/${params}`, params, success)
    },
    getOpenAuthCode: (params, success) => {
        return request('GET', `${API}/openapi/v1/auth/getAuthCode`, params, success)
    },
    // 我的发票列表
    searchInvoiceList: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/searchList`, params, success)
    },
    // 待开发票列表
    searchOrderInvoiceCountList: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/searchOrderInvoiceCountList`, params, success)
    },

    // 订单发票详情
    getInvoiceById: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/getById/${params}`, params, success)
    },
    // 获取用户信息
    getLoginUserByAuthCode: (params) => {
        return request('GET', `${API}/ucauth/getLoginUserByAuthCode`, params)
    },
    // UC用户退出
    userLogout: (params) => {
        return request('POST', `${API}/ucauth/userLogout`, params)
    },
    // 获取临时上传文件token
    getTempUploadImgUrl: (params) => 
        request('BODY', `${API}/v1/common/upload/getUploadTokenList`, params),
    // 上传图片
    uploadImg: (url, body) => axios({
        method: 'put',
        url,
        data: body,
        headers: {
            'Content-Type': "image/png"
        }
    })

};
//支付模块接口
export const PayFetch = {
    //构建支付宝支付参数
    buildWebAlipayApi: (params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWebAlipayApi`, params, success)
    },
    //获取微信支付参数
    buildWeixinScanpayApi: (orderId, params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWeixinScanpayApi/${orderId}${params}`, params, success)
    },
    //获取订单财务状态
    getFinanceStatus: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getFinanceStatus/${params}`, params, success)
    },
    // 组合支付
    combinationPay: (params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/order/combinationPay`, params, _success, _error)
    },
    // 易生聚合支付配置
    getYiShengPayConfig: (params, _success, _error) => {
        return request('GET', `${API}/v1/finance/aggregatePayConfig/getByType/1`, null, _success, _error)
    },
    // 易生扫码支付
    yiShengScanPay: (params, _success, _error) => {
        return request('POST', `${API}/v1/yiShengPay/apply/scanPay`, params, _success, _error)
    },
    // 京东支付
    jdOrderPcCashier: (params, _success, _error) => {
        return request('BODY', `${API}/v1/jdPay/apply/orderPcCashier`, params, _success, _error)
    },
    // mercadoPago支付
    buildMercadoPagoQRApi: (params, success) => {
        return request('GET', `${API}/v1/mercadoPago/pay/buildMercadoPagoQRApi`, params, success)
    },
};
// 退货
export const SaleBackFetch = {
    // 申请退货
    save: (params, success) => {
        return request('BODY', `${API}/v1/sale/saleBack/save`, params, success)
    },
    // 退货列表
    searchList: (params, success) => {
        return request('BODY', `${API}/shopping/saleBack/getPage`, params, success)
    },
    // 退货列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/getById/${params}`, params, success)
    },
};
// 免费领
export const FreeGoodsFetch = {
    //购物车查看对应客户类型可参与的免费活动信息
    freeActivityByClientType: (params, success) => {
        return request('GET', `${API}/v1/sl/freeGoodsActivity/freeActivityByClientType`, params, success)
    },
};
// 串号查询
export const GoodsSnFetch = {
    //查询指定企业串号查询设置, 企业id为空时,企业id=登录用户企业
    getSearchSetting: (params, success) => {
        return request('GET', `${API}/v1/house/goodsSnSearchSetting/noAuth/getSearchSetting`, params, success)
    },
    //精确查询串号信息, 企业id为空时,企业id=登录用户企业
    getByCode: (params, success) => {
        return request('GET', `${API}/v1/house/goodsSn/noAuth/getByCode`, params, success)
    },
};
// 自提点
export const PickUpStationFetch = {
    //分页查询自提点
    searchList: (params, success) => {
        return request('GET', `${API}/v1/house/selfPickSite/searchListForShop`, params, success)
    },
    //客户默认自提点
    getClientDefaultSite: (params, success) => {
        request('POST', `${API}/v1/house/selfPickSite/getClientDefaultSite/${params}`, params, success)
    },
};
export const CouponFetch = {
    //获取客户优惠券
    listCouponByShop: (params, success) => {
        return request('BODY', `${API}/shopping/coupon/getClientCouponPage`, params, success)
    },
    //获取客户相关优惠券活动
    listCouponActivityByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponActivityByShop`, params, success)
    },

    //分页客户相关优惠券活动
    getClientCouponPageForShop: (params, success) => {
        return request('BODY', `${API}/v1/shop/coupon/getClientCouponPageForShop`, params, success)
    },

    //领取优惠券
    receiveCoupon: (params, success) => {
        return request('BODY', `${API}/v1/shop/coupon/receiveCoupon`, params, success)
    },
    //批量领取优惠券
    batchReceiveCoupon: (params, success) => {
        return request('BODY', `${API}/v1/shop/coupon/batchReceiveCoupon`, params, success)
    },
};
// 退货
export const RefundFetch = {
    // 退款确认
    confirmRefund: (params, success) => {
        return request('BODY', `${API}/shopping/saleRefund/refundConfirm`, params, success)
    },
    // 退货列表
    searchList: (params, success) => {
        return request('BODY', `${API}/shopping/saleRefund/getPage`, params, success)
    },
    // 根据订单id查询退款商品信息
    getById: (orderId, success) => {
        return request('GET', `${API}/shopping/saleRefund/getRefundProductsByOrderId/${orderId}`, orderId, success)
    },
    // 退款单详情
    getRefundDetail: (refundId, success) => {
        return request('GET', `${API}/shopping/saleRefund/getDetailByRefundId/${refundId}`, refundId, success)
    },
};